import './App.css';
import { Comunity } from './Components/Comunity/Comunity';
import { Footer } from './Components/Footer/Footer';
import { Hero } from './Components/Hero/Hero';
import { Plans } from './Components/Plans/Plans';
import { Programs } from './Components/Programs/Programs';
import { Reasons } from './Components/Reasons/Reasons';
function App() {
  return (
    <div className="App">
        <Hero/>
        <Programs/>
        <Reasons/>
        <Plans/>
        <Comunity/>
        <Footer/>
    </div>
  );
}

export default App;
