import React from 'react';
import './Hero.css';
import Hero_img from './../../assets/hero_image.png';
import hero_back from './../../assets/hero_image_back.png';
import calories from './../../assets/calories.png';
import heart from './../../assets/heart.png';
import { Header } from '../Header/Header'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

export const Hero = () => {
  const transition={type:'spring',duration:3}
  const mobile=window.innerWidth<=768 ? true:false;
  return (
    <div className='hero' id='home'>
        <div className="h-left">
           <Header/>
           <div className="best-ad">
            <motion.div
              initial={{left:mobile?'165px':'238px'}}
              whileInView={{left:'8px'}}
              transition={{...transition,type:'tween'}} 
              >
            
            </motion.div>
              <span>THE BEST FITNESS CLUB IN THE TOWN</span>
           
           
           </div>
           {/* hero text */}
           <div className="hero-text">
            <div><span className='stroke-text'>SHAPE</span>
            <span> YOUR</span>
            </div>
            <div>
            <span>Ideal Body</span>
            
            </div>
            <div>
                <span>We are here to help you to live your life at its best shape</span>
            </div>
           </div>
           {/* figures */}
           <div className="figures">
            <div className='fig-content'>
                <span>
                  <NumberCounter end={140} start={100} delay='3' preFix="+"></NumberCounter>
                </span>
                <span>expert coaches</span>
                </div>
            <div className='fig-content'>
            <span>
            <NumberCounter end={978} start={900} delay='3' preFix="+"></NumberCounter>
            </span>
            <span>Members Joined</span>
            </div>

            <div className='fig-content'>
            <span>
            <NumberCounter end={50} start={20} delay='3' preFix="+"></NumberCounter>
            </span>
            <span>Fitness Programs</span>
            </div>
           </div>
           {/* hero buttons */}
           <div className='hero-btn'>
           <button className="btn">Get Started</button>
           <button className="btn">Learn More</button>
           </div>
        </div>
        <div className="h-right">
            {/* <button className='btn'>Join Now</button> */}
            {/* heart rate */}
            <motion.div
            initial={{right:'-1rem'}}
            whileInView={{right:'4rem'}}
            transition={transition}
            className="heart-rate">
              <img src={heart} alt="" />
              <span>Heart Rate</span>
              <span>
              <NumberCounter end={116} start={100} delay='4' postFix="bpm"></NumberCounter>
              </span>
            </motion.div>
            {/* hero image */}
            <img src={Hero_img} alt="" className='hero-img'/>
            <motion.img 
             initial={{right:'11rem'}}
             whileInView={{right:'20rem'}}
             transition={transition}
            src={hero_back} alt="" className='hero-back' />
            {/* calories */}
            <motion.div
             initial={{right:'37rem'}}
             whileInView={{right:'28rem'}}
             transition={transition}
             className="calories">
              <img src={calories} alt="" />
              <div>
                <span>Calories Burned</span>
                <span>220 kcal </span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}
