import React, { useRef } from 'react'
import './Comunity.css'
 import emailjs from '@emailjs/browser'
export const Comunity = () => {
  const form=useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3ehrfn8', 'template_i2kr0x3', form.current, 'P3PuFB8CQDpuzlNt1')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='Community' id='Community'>
        <div className="left-c">
          <hr />
          <div>
         <span className='stroke-text'>Get Ready to </span>
         <span>Live Your Life</span>
         </div>
         <div>
         <span>In your Desired</span>
         <span className='stroke-text'> Shape</span>
         </div>
        </div>
        <div className="right-c">
         <form ref={form} action="" className='email-container' >
          <input type="email" name='userEmail' placeholder='Enter Your Email' />
          <button className='btn btn-j'>Join</button>
         </form>
        </div>
    </div>
  )
}
