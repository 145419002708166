import React from "react";
import "./Programs.css";
import Running from "./../../assets/dumbell.svg";
import arrow from "./../../assets/rightArrow.png";
import {programsData} from '../../data/programsData';
export const Programs = () => {
  return (
    <div>
      <div className="program-headers" id="programs">
        <span className="stroke-text">EXPLORE OUR</span>
        <span>PROGRAMS</span>
        <span className="stroke-text">TO SHAPE YOU</span>
      </div>
      {/* <div className="program-boxes">
        <div className="box">
          <img className="box-img" src={Running} alt="" />
          <span>Strength Training</span>
          <p>
            In this program you are trained to improve your strength by
            different execises
          </p>
          <div className="Join-btn">
            <span>Join Now</span>
            <img src={arrow} alt="" />
          </div>
        </div>
        <div className="box">box2</div>
        <div className="box">box3</div>
        <div className="box">box4</div>
      </div> */}
      <div className="program-types">
       {programsData.map((program)=>(
        <div className="category">
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-button">
                <span>Join Us</span>
                <span><img src={arrow} alt="" /></span>
            </div>
        </div>
       ))
       }

      </div>
    </div>
  );
};
