import React from 'react'
import './Reasons.css'
import image1 from './../../assets/image1.png';
import image2 from './../../assets/image2.png';
import image3 from './../../assets/image3.png';
import image4 from './../../assets/image4.png';
import nb from './../../assets/nb.png';
import adidas from './../../assets/adidas.png';
import nike from './../../assets/nike.png';
import tick from './../../assets/tick.png'
export const Reasons = () => {
  return (
    <div className='reasons' id='reasons'>
        <div className="left-r">
       <img src={image1} alt="" />
       <img src={image2} alt="" />
       <img src={image3} alt="" />
       <img src={image4} alt="" />
        </div >
        <div className="right-r">
      <span>Some Reasons</span>
      <div className='reason-text'>
        <span className='stroke-text'>Why</span>
        <span> Choose Us?</span>
      </div>
       <div className="details-r">
        <div>
          <span><img src={tick} alt="" /></span>
          <span>Over 100+ skilled trainers</span>
        </div>
        <div>
          <span><img src={tick} alt="" /></span>
          <span>Train smarter and faster than before</span>
        </div>
        <div>
          <span><img src={tick} alt="" /></span>
          <span>1 Free Program for new Member</span>
        </div>
        <div>
          <span><img src={tick} alt="" /></span>
          <span>Reliable Partners</span>
        </div>

       </div>
       <div className="partners">
        OUR PARTNERS
        <div className="partner-pics">
         <img src={nb} alt="" />
         <img src={adidas} alt="" />
         <img src={nike} alt="" />

        </div>
       </div>
        </div>
        </div>
  )
}
