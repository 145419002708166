import React from "react";
import { useState } from "react";
import "./Header.css";
import bars from "./../../assets/bars.png";
import { Link } from "react-scroll";
export const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setmenuOpened] = useState(false);
  return (
   
    <div className="header">
      <div className="logo">
        {/* <img src={logo} alt="" /> */}
        <div className="logo-text">
          <span className="stroke-text">Shapes</span>
        </div>
      </div>
      {mobile === true && menuOpened===false ? (
        <div style={{
          backgroundColor:"var(--appColor)",
          padding:"0.5rem",
          paddingTop:"0",
          borderRadius:"5px"}}
          onClick={()=>setmenuOpened(true)}>
        <img src={bars} alt="" style={{width:'25px', height:'19px',paddingTop:'1rem'}} />
        </div>
      ) : (
        <ul className="header-menu">

          <li>
            <Link
             onClick={()=>setmenuOpened(false)}
             activeClass="active"
            to='home'
            span={true}
            smooth={true}
            >Home</Link>
            </li>
          <li>
          <Link
             onClick={()=>setmenuOpened(false)}
            to='programs'
            span={true}
            smooth={true}
            >Programs</Link>
          </li>
          <li>
          <Link
             onClick={()=>setmenuOpened(false)}
            to='reasons'
            span={true}
            smooth={true}
            >Why Us</Link>
          </li>
          <li>
          <Link
             onClick={()=>setmenuOpened(false)}
            to='plans'
            span={true}
            smooth={true}
            >Plans</Link>
          </li>
          <li>
          <Link
             onClick={()=>setmenuOpened(false)}
            to='Community'
            span={true}
            smooth={true}
            >Join Us</Link>
          </li>
        </ul>
      )}
    </div>
  );
};
