 import React from 'react'
 import './Plans.css'
 import {plansData} from '../../data/plansData'
 
 import whiteTick from './../../assets/whiteTick.png'
 
 export const Plans = () => {
   return (
     <div className='plan-container' id='plans'>
         <div className="plan-headers">
        <span className="stroke-text">Ready To Start</span>
        <span>Your Journey</span>
        <span className="stroke-text">Now with Us</span>
      </div>
      {/* plans */}
      <div className="plans">
        {plansData.map((plans,i)=>(
            <div className='plan'>
                {plans.icon}
                <span>{plans.name}</span>
               <span>$ {plans.price}</span>
               <div className='features'>
                {plans.features.map((feature,i)=>(
                    <div className="feature">
                        <img src={whiteTick} alt="" />
                        <span key={i}>{feature}</span>
                    </div>
                ))}
               </div>
               <div>
                <span>See More Benefits &#8594;</span>
               
               </div>
               <button className='btn'>Join Now</button>
            </div>
         

        )

        )}
      </div>
     </div>
   )
 }
 